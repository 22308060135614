import {httpGet} from '@/plugins/request'

/**
 * 获取用户列表
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetMembers(query = [])
{
    return httpGet('/admin/member', query)
}

/**
 * 获取家庭组
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetGroups(query = [])
{
    return httpGet('/admin/group', query)
}
